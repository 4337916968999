import React from 'react'
import CircleIcon from "../../../assets/CirclesIcon.svg";
import PlusIcon from "../../../assets/plusIcon.svg";

const Section3 = () => {
    const data = [
        {
          content: "Grow your portfolio",
          hoverText: "Seamlessly grow your portfolio by getting new coins every month"
        },
        {
          content: "Unthink Crypto",
          hoverText: "Enjoy your monthly deposits and let us take care of everything else"
        },
        {
          content: "Secure and easy",
          hoverText: "Own your keys to your crypto world fearlessly 24/7"
        }
      ];
  return (
    <>
     <div className="w-full  h-[800px] relative bg-neutral-950 rounded-[65px] flex justify-center" id="services">
        <div className=" 2xl:w-[1240px]  absolute justify-start items-start gap-[100px] lg:inline-flex mt-16">
          <div className="px-6  lg:justify-center lg:items-center gap-2.5 flex">
            <div className="text-center text-zinc-100 text-[50px] lg:text-[80px] font-normal bebas-neue-regular">
              What’s New
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-[11px] inline-flex">
          {data.map((item, index) => (
        <div
          key={index}
          className="w-full lg:w-[551px] min-h-[110px] lg:min-h-[110px] justify-center items-center inline-flex"
        >
          <div className="w-full lg:w-[551px] self-stretch pl-8 pr-6 lg:px-3 py-0 lg:py-6 flex-col justify-center items-start gap-6 inline-flex"
          >
            
              <div className="btn w-full btn-primary" >
      <span className="btn_text w-full " data-text={item.hoverText}>
          <div className="w-full  justify-between items-center gap-5 flex ">
                  <div className="text-center text-stone-300 text-[26px] lg:text-[40px] font-medium  ">
                    {item.content}
                  </div>
                  <div className="p-2 bg-zinc-300 rounded-xl justify-center items-center gap-2.5 flex">
                    <img src={PlusIcon} alt="ok" className="w-8 h-8 relative" />
                  </div>
                  </div>
      </span>
    </div>
      <svg xmlns="http://www.w3.org/2000/svg" className="w-full" width="551" height="2" viewBox="0 0 551 2" fill="none">
<path d="M0 1H551" stroke="white" strokeOpacity="0.2"/>
</svg>
          </div>
        </div>
      ))}
          </div>
        </div>
        <div className="h-[500px] lg:h-[568px] px-[11px] pt-12 lg:pt-0 pb-[13px] left-[-90px] top-[536px] lg:top-[303px] absolute opacity-50 mix-blend-lighten flex-col justify-end items-center inline-flex">
          <img className="w-[520px] h-[507px]" src={CircleIcon} alt="ok" />
        </div>
      </div>
    </>
  )
}

export default Section3