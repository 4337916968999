import React, {  useState } from "react";
import MuiButton, { buttonClasses } from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";
import arrowDown from '../assets/arrow-down.svg'
const Button = styled(MuiButton)(({ theme }) => ({
  [`&.${buttonClasses.root}`]: {
    minWidth: "0px !important",
    padding: "12px 8px",
  },
}));
export default function ReusableMenu({
  buttonIcon,
  handleDelete=()=>{},
  menuItems = [],
  handleChangeFun=()=>{},
  buttonText = "",
  isLib=false,isHeader=false,
  isTab=false,
  handleFolder=()=>{}
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value,setValue]=useState("English")
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{ display: "flex", alignItems: "center", }} // Add this style to the Button
        onClick={handleClick}>
        {buttonText && (
          <div className="text-center text-white text-[20px]  capitalize leading-[21px]" >
            {buttonText}
          </div>
        )}
         {value&&isLib && (
          <div className="text-center text-white text-sm font-medium capitalize leading-[21px]" >
            {value.length>10?`Filter: ${value?.slice(0,10)}..`:`Filter: ${value}`}
          </div>
        )}
        <img
          className={` ${isHeader?"h-[30px] w-[30px]":"h-[30px] w-6"}] ${buttonText ? "ml-2" : "ml-5"}`} // Adjust the margin or padding as needed
          src={arrowDown}
          alt="icon"
          />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        >
        {menuItems.map((item, index) => (
          <MenuItem
            key={item?.text}
            onClick={() => {
              setValue(item?.text)
              handleChangeFun(item?.text)
              handleClose();
            }}
            sx={{width:isHeader?'120px':isLib||isTab?"100%":"", }}
            >
            <div className="flex items-center text-white ">
              {item?.icon && (
                <div className="w-6 h-6 mr-2">
                  <img className="w-full h-full" src={item?.icon} alt="icon" />
                </div>
              )}
              {item.text}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
