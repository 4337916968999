import React from 'react'
import HeroSec1 from "../../../assets/heroSect1.png";
import HeroSecMob from "../../../assets/HeroSecMob.png";
import {
    AppleIcon,
    PlayStoreIcon,
  } from "../../../assets/custom-icons";

const Section1 = ({innerWidth}) => {
  return (
    <>
    
    <main className="flex-1 items-center justify-center w-full overflow-y-auto p-5 pt-[60px]">
          <div className="flex-col justify-start items-center gap-[45px] ">
            <div className="self-stretch flex-col justify-start items-center gap-3">
              <div className="text-center text-zinc-100 text-[50px] md:text-[90px] uppercase bebas-neue-regular font-normal leading-[60px] md:pt-8 md:leading-[90px]" >
              SUBSCRIBE NOW AND 
                <br />
                KICKSTART YOUR MONTHLY  <br/> CRYPTO DELIVERIES            </div>
              <div className="text-center text-zinc-100 pb-8 pt-4 text-[22px] font-medium font-Urbanist leading-[46px]">
                Create your wallet. Cancel anytime. Start by downloading the App today!
              </div>
            </div>
            <div className="flex justify-center items-center flex-col md:flex-row gap-8 pt-8 lg:pt-0">
            <div className="border-wrap rounded-full">

              <button className="px-8 py-4 button-pos rounded-[32px] module justify-center items-center gap-2.5 flex">
                <PlayStoreIcon className="w-8 h-8 relative" />
                <div className="text-zinc-300 text-xl md:text-2xl font-medium font-Urbanist">
                  Get it on Google Play
                </div>
              </button></div>
              <div className="border-wrap rounded-full">

              <button className="px-8 py-4 button-pos rounded-[32px] module justify-center items-center gap-2.5 flex">
                <AppleIcon className="w-8 h-8 relative" />
                <div className="text-zinc-300 text-xl md:text-2xl font-medium font-Urbanist">
                  Download on the App Store
                </div>
              </button></div>
            </div>
          </div>

          {/* <div className="pt-20 lg:pt-24 flex items-center justify-center   ">
          {innerWidth>899?
          <img src={HeroSec1} className="w-[90%] 2xl:w-[1240px]" alt="ok" />:
          <img src={HeroSecMob} className="w-[90%]" alt="ok" />
        }
          </div> */}
          {/* <div className="items-center justify-center flex flex-col lg:flex-row py-16">
            <div className="w-[90%] lg:w-[59%] 2xl:w-[780px] pt-8 lg:pt-10 md:pt-20  lg:gap-5  flex flex-col lg:flex-row   justify-between gap-4">
              <div className="w-full lg:w-[35%] text-zinc-100 text-[35px] md:text-[60px] font-normal bebas-neue-regular">
                What’s your
                <br />
                 &nbsp;Challenge?
              </div>
              <div className="w-full  lg:w-[60%]">
                <div className=" text-zinc-100 text-[18px] font-medium   leading-[46px]">
                  Simply choose your monthly plan and a deposit will be made
                  into your crypto wallet monthly. We do all the work and
                  research to find new and time-tested projects to add to your
                  portfolio
                </div>
                <button className="px-5 md:px-8 py-4 bg-gradient-to-b from-indigo-500 to-cyan-400 rounded-[32px] justify-center items-center gap-2.5 inline-flex">
                  <div className="text-zinc-100 text-xl font-semibold  ">
                    Download the App now
                  </div>
                </button>
              </div>
            </div>
          </div> */}
        </main>
    </>
  )
}

export default Section1