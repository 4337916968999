import React from "react";
import './terms.css'
const TermsAndCondtions = () => {
  return (
    <>
   
   <div class=" w-full px-4 text-center flex justify-center items-center " >
         <div class=" 2xl:w-[1240px]">
         <div align="center" style={{textAlign: "left"}}>
    <div style={{ textAlign: "center"}} className="py-2">
        <span className="text-[17px] "><strong>Agreement To Our Legal Terms</strong></span><br/>
        <span className="text-[15px] "><strong>Last updated April 30, 2024</strong></span>

    </div>
</div>

<div align="center" style={{textAlign: "left"}}>
        <span style={{fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166"}}>
            We are 
            <bdt className="block-container" data-id="9d459c4e-c548-e5cb-7729-a118548965d2" data-type="question"> Somos Blockchain, INC</bdt>
            (
            "<strong>Company</strong>," "<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>"
            )
            <span style={{fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166"}}>
                , a company registered in
                <bdt className="block-component"></bdt>
                <bdt className="block-component"></bdt>
                <bdt className="block-component"></bdt>
                <span style={{fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166"}}>
                    <span style={{fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166"}}>
                        <bdt className="question"> Delaware</bdt>
                        , 
                        <bdt className="question">United States</bdt>
                    </span>
                </span>
                .
            </span>
            at 
            <bdt className="question"> 8 The Green</bdt>
            , 
            <bdt className="question">Dover</bdt>
            <span style={{fontSize: "15px"}}>
                <span >
                    <span style={{fontSize: "15px"}}>
                        <span >
                            <span style={{}}>
                                <bdt className="block-component"></bdt>
                                <bdt className="block-component"></bdt>
                                , 
                                <bdt className="question">DE</bdt>
                                <bdt className="statement-end-if-in-editor"></bdt>
                                <bdt className="block-component"></bdt>
                                <bdt className="block-component"></bdt>
                                <bdt className="question">19901</bdt>
                                <bdt className="statement-end-if-in-editor"></bdt>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
            <span style={{fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166"}}>
                <span style={{fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166"}}>
                    <bdt className="else-block"></bdt>
                </span>
            </span>
            .
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
</div>

<div align="center" style={{ lineHeight: "1" }}>
    <br />
</div>
<div align="center" style={{ textAlign: "left" }}>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            We operate
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
             the mobile application
            <bdt className="question"> Somos Blockchain, Inc</bdt>
            (the
            <bdt className="block-component"></bdt>
            "<strong>App</strong>"
            <bdt className="statement-end-if-in-editor"></bdt>
            )
            <bdt className="statement-end-if-in-editor"></bdt>
            , as well as any other related products and services that refer or link to these legal terms (the "<strong>Legal Terms</strong>"
            ) (collectively, the "<strong>Services</strong>").
        </span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1" }}>
        <br />
    </div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            You can contact us by phone at
            <bdt className="question"> 1-800-475-0691</bdt>
            , email at
            <bdt className="question"> <a href="mailto:somos@somosblockchain.com">somos@somosblockchain.com</a></bdt>
            <bdt className="block-component"></bdt>
            ,
            <bdt className="statement-end-if-in-editor"></bdt>
            or by mail to
            <bdt className="question"> 8 The Green</bdt>
            <bdt className="block-component"></bdt>
            ,
            <bdt className="question">Dover</bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            ,
            <bdt className="question">DE</bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="question">19901</bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component">,&nbsp;</bdt>
            <bdt className="question">United States</bdt>
            .
        </span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1" }}>
        <br />
    </div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity (
            <bdt className="block-component"></bdt>
            "<strong>you</strong>"
            <bdt className="statement-end-if-in-editor"></bdt>
            ), and
            <bdt className="question">Somos Blockchain, INC</bdt>
            , concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. If you do not agree with all of these legal terms, then you are expressly prohibited from using the services and you must discontinue use immediately.
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
        </span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1" }}>
        <br />
    </div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying you by
            <bdt className="question"> <a href="mailto:somos@somosblockchain.com">somos@somosblockchain.com</a></bdt>
            , as stated in the email message. By continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms.
            <bdt className="block-component"></bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </div>
</div>
<div align="center" style={{ lineHeight: "1" }}>
    <br />
</div>

            <div align="center" style={{textAlign:"left"}}>
            <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <bdt className="block-container if" data-type="if" id="a2595956-7028-dbe5-123e-d3d3a93ed076">
        <bdt data-type="conditional-block">
            <bdt data-type="body">
                <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
                    <bdt className="block-component"></bdt>
                    <bdt className="block-container if" data-type="if" id="a2595956-7028-dbe5-123e-d3d3a93ed076">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body"><span style={{ fontSize: "14.6667px" }}>The Services are intended for users who are at least 13 years of age. All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their parent or guardian to use the Services. If you are a minor, you must have your parent or guardian read and agree to these Legal Terms prior to you using the Services.</span></bdt>
                        </bdt>
                    </bdt>
                    
                </span>
            </bdt>
        </bdt>
    </bdt>
</div>

<div className="MsoNormal" style={{ lineHeight: "1" }}><br /></div>
<div className="MsoNormal"  style={{ lineHeight: "1.5" }}>We recommend that you print a copy of these Legal Terms for your records.</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
<div className="MsoNormal" data-custom-class="heading_1" style={{ lineHeight: "1.5" }}><strong>TABLE OF CONTENTS</strong></div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}><a href="#services"><span ><span ><span >1. OUR SERVICES</span></span></span></a></div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}><a  href="#ip"><span style={{ color: "black" }}><span >2. INTELLECTUAL PROPERTY RIGHTS</span></span></a></div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}><a  href="#userreps"><span ><span >3. USER REPRESENTATIONS</span></span></a></div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
   
    <a  href="#userreg"><span ><span >4. USER REGISTRATION</span></span></a>
    <span style={{ fontSize: "15px" }}>
        <span >
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </span>
    <a  href="#products"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#products">
        <span >
            <span >
                <bdt className="block-component"></bdt>
                <bdt className="block-component"></bdt>
                5. PRODUCTS
                <bdt className="statement-end-if-in-editor"></bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
    <a  href="#purchases"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#purchases">
        <span >
            <span >
                <bdt className="block-component"></bdt>
                6. PURCHASES AND PAYMENT
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
    <a  href="#subscriptions"><span ><span >7. SUBSCRIPTIONS</span></span></a>
    <bdt className="statement-end-if-in-editor"><span style={{ fontSize: "15px" }}></span></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "15px" }}>
        <span >
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
        </span>
    </span>
    <a  href="#returnno">
        <span >
            <span >
                8.
                <bdt className="block-component"></bdt>
                &nbsp;REFUNDS POLICY
            </span>
        </span>
    </a>
    <span style={{ fontSize: "15px" }}>
        <span >
            <bdt className="statement-end-if-in-editor"></bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </span>
    <a  href="#software"></a> <a  href="#software"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#software">
        <span >
            <span >
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </a>
    <a  href="#prohibited"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#prohibited">
        <span >
            <span >
                <bdt className="block-component"></bdt>
                9. PROHIBITED ACTIVITIES
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </a> <a  href="#ugc"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#ugc">
        <span >
            <span >
                <bdt className="block-component"></bdt>
                10. USER GENERATED CONTRIBUTIONS
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </a> <a  href="#license"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#license">
        <span >
            <span >
                11. CONTRIBUTION
                <bdt className="block-component"></bdt>
                LICENSE
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
    <a  href="#reviews"></a>
</div>

<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#reviews">
        <span style={{ color: "black", fontSize: "15px" }}>
            <span >
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </a>
    <a  href="#mobile"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#mobile">
        <span >
            <span >
                <bdt className="block-component"></bdt>
                12. MOBILE APPLICATION
                <bdt className="block-component"></bdt>
                LICENSE
                <bdt className="statement-end-if-in-editor"></bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
    <a  href="#socialmedia"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#socialmedia">
        <span >
            <span >
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </a>
    <a  href="#thirdparty"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#thirdparty">
        <span>
            <span >
                <bdt className="block-component"></bdt>
                13. THIRD-PARTY WEBSITES AND CONTENT
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
    <a  href="#advertisers"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#advertisers">
        <span >
            <span >
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </a>
    <a  href="#sitemanage"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#sitemanage">
        <span >
            <span >
                <bdt className="block-component"></bdt>
                14. SERVICES MANAGEMENT
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
    <a  href="#ppyes"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#ppyes">
        <span >
            <span >
                <bdt className="block-component"></bdt>
            </span>
        </span>
    </a>
    <a  href="#ppno"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#ppno">
        <span >
            <span >
                <bdt className="block-component"></bdt>
                15. PRIVACY POLICY
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
    <a  href="#dmca"></a>
</div>

<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#dmca">
        <span style={{ color: "black", fontSize: "15px" }}>
            <span >
                <bdt className="block-component"></bdt>
                <bdt className="block-component"></bdt>
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <span>
        <span >
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
        </span>
    </span>
    <a  href="#terms"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#terms">
        <span >
            <span >
                16. TERM AND TERMINATION
            </span>
        </span>
    </a>
    <a  href="#modifications"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#modifications">
        <span >
            <span >
                17. MODIFICATIONS AND INTERRUPTIONS
            </span>
        </span>
    </a>
    <a  href="#law"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#law">
        <span >
            <span >
                18. GOVERNING LAW
            </span>
        </span>
    </a>
    <a  href="#disputes"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#disputes">
        <span >
            <span >
                19. DISPUTE RESOLUTION
            </span>
        </span>
    </a>
    <a  href="#corrections"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#corrections">
        <span >
            <span >
                20. CORRECTIONS
            </span>
        </span>
    </a>
    <a  href="#disclaimer"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#disclaimer">
        <span >
            <span >
                21. DISCLAIMER
            </span>
        </span>
    </a>
    <a  href="#liability"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#liability">
        <span >
            <span >
                22. LIMITATIONS OF LIABILITY
            </span>
        </span>
    </a>
    <a  href="#indemnification"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#indemnification">
        <span >
            <span >
                23. INDEMNIFICATION
            </span>
        </span>
    </a>
    <a  href="#userdata"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#userdata">
        <span >
            <span >
                24. USER DATA
            </span>
        </span>
    </a>
    <a  href="#electronic"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#electronic">
        <span >
            <span >
                25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            </span>
        </span>
    </a>
    <a  href="#california"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <span >
        <a  href="#sms"><span >26. SMS TEXT MESSAGING</span></a>
    </span>
    <bdt className="statement-end-if-in-editor"><span style={{ fontSize: "15px" }}></span></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#california">
        <span >
            <span >
                <bdt className="block-component"></bdt>
                27. CALIFORNIA USERS AND RESIDENTS
                <bdt className="statement-end-if-in-editor"></bdt>
            </span>
        </span>
    </a>
    <a  href="#misc"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#misc">
        <span >
            <span >
                28. MISCELLANEOUS
            </span>
        </span>
    </a>
    <a  href="#contact"></a>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5" }}>
    <a  href="#contact">
        <span>
            <span >
                29. CONTACT US
            </span>
        </span>
    </a>
</div>


<div align="center" style={{ textAlign: "left" }}>
    <div className="MsoNormal" data-custom-class="heading_1" style={{ lineHeight: "1.5" }}>
        <a name="_b6y29mp52qvx"></a>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal" data-custom-class="heading_1" id="services" style={{ lineHeight: "1.5" }}>
        <strong><span >1. OUR SERVICES</span></strong>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
            The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
            <bdt className="block-component"></bdt>
        </span>
        <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
            The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
            <bdt className="block-component"></bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
</div>
<div align="center" data-custom-class="heading_1" style={{ textAlign: "left", lineHeight: "1.5" }}>
    <strong><span id="ip" >2. INTELLECTUAL PROPERTY RIGHTS</span></strong>
</div>
<div align="center" style={{ lineHeight: "1.5" }}><br /></div>
<div align="center" style={{ textAlign: "left" }}>
    <div className="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5" }}><strong>Our intellectual property</strong></div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the
            <bdt className="block-component"></bdt>
            "Content"
            <bdt className="statement-end-if-in-editor"></bdt>
            ), as well as the trademarks, service marks, and logos contained therein (the
            <bdt className="block-component"></bdt>
            "Marks"
            <bdt className="statement-end-if-in-editor"></bdt>
            ).
        </span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>Our Content and Marks are protected by copyright and trademark laws (and various
            other intellectual property rights and unfair competition laws) and treaties in the United
            States and around the world.</span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            The Content and Marks are provided in or through the Services
            <bdt className="block-component"></bdt>
            "AS IS"
            <bdt className="statement-end-if-in-editor"></bdt>
            for your
            <bdt className="block-component"></bdt>
            personal, non-commercial use
            <bdt className="block-component"></bdt>
            only.
        </span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5" }}><strong>Your use of our Services</strong></div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "15px" }}>
            Subject to your compliance with these Legal Terms, including the
            <bdt className="block-component"></bdt>
            "
            <bdt className="statement-end-if-in-editor"></bdt>
        </span>
        <a  href="#prohibited"><span style={{ color: "black", fontSize: "15px" }}>PROHIBITED ACTIVITIES</span></a>
        <span style={{ fontSize: "15px" }}>
            <bdt className="block-component"></bdt>
            "
            <bdt className="statement-end-if-in-editor"></bdt>
            section below, we grant you a non-exclusive, non-transferable, revocable
            <bdt className="block-component"></bdt>
            license
            <bdt className="statement-end-if-in-editor"></bdt>
            to:
        </span>
    </div>
    <ul>
        <li className="MsoNormal"  style={{ lineHeight: "1.5" }}><span style={{ fontSize: "15px" }}>access the Services; and</span></li>
        <li className="MsoNormal"  style={{ lineHeight: "1.5" }}><span style={{ fontSize: "15px" }}>download or print a copy of any portion of the Content to which you have properly gained access.</span></li>
    </ul>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            solely for your
            <bdt className="block-component"></bdt>
            personal, non-commercial use
            <bdt className="block-component"></bdt>
            .
        </span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}><span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced,
        aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise exploited
        for any commercial purpose whatsoever, without our express prior written
        permission.</span>
    </div>
    <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
    <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to:
            <bdt className="question"><a href="mailto:somos@somosblockchain.com">somos@somosblockchain.com</a></bdt>
            . If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
        </span>
    </div>
</div>


<div align="center" style={{ lineHeight: "1.5" }}><br/></div>
<div align="center" style={{ textAlign: "left" }}>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}><span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.</span></div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}><span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.</span></div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
   <div className="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         <strong>
            Your submissions
         </strong>
      </span>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "15px" }}>
         Please review this section and the 
         <bdt className="block-component"></bdt>
         "
         <bdt className="statement-end-if-in-editor"></bdt>
         <a  href="#prohibited"><span style={{ color: "black", fontSize: "15px" }}>PROHIBITED ACTIVITIES</span></a>
         <bdt className="block-component"></bdt>
         "
         <bdt className="statement-end-if-in-editor"></bdt>
         section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
      </span>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "15px" }}>
         <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services (
         <bdt className="block-component"></bdt>
         "Submissions"
         <bdt className="statement-end-if-in-editor"></bdt>
         ), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
         <bdt className="block-component"></bdt>
      </span>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "15px" }}>
         <strong>You are responsible for what you post or upload:</strong> By sending us Submissions
         <bdt className="block-component"></bdt>
         through any part of the Services
         <bdt className="block-component"></bdt>
         you:
      </span>
   </div>
   <ul>
      <li className="MsoNormal"  style={{ lineHeight: "1.5" }}>
         <span style={{ fontSize: "15px" }}>
            confirm that you have read and agree with our 
            <bdt className="block-component"></bdt>
            "
            <bdt className="statement-end-if-in-editor"></bdt>
         </span>
         <a  href="#prohibited"><span style={{ color: "black", fontSize: "15px" }}>PROHIBITED ACTIVITIES</span></a>
         <span style={{ fontSize: "15px" }}>
            <bdt className="block-component"></bdt>
            "
            <bdt className="statement-end-if-in-editor"></bdt>
            and will not post, send, publish, upload, or transmit through the Services any Submission
            <bdt className="block-component"></bdt>
            that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;
         </span>
      </li>
      <li className="MsoNormal"  style={{ lineHeight: "1.5" }}>
         <span style={{ fontSize: "15px" }}>
            to the extent permissible by applicable law, waive any and all moral rights to any such Submission
            <bdt className="block-component"></bdt>
            ;
         </span>
      </li>
      <li className="MsoNormal"  style={{ lineHeight: "1.5" }}>
         <span style={{ fontSize: "15px" }}>
            warrant that any such Submission
            <bdt className="block-component"></bdt>
            are original to you or that you have the necessary rights and 
            <bdt className="block-component"></bdt>
            licenses
            <bdt className="statement-end-if-in-editor"></bdt>
            to submit such Submissions
            <bdt className="block-component"></bdt>
            and that you have full authority to grant us the above-mentioned rights in relation to your Submissions
            <bdt className="block-component"></bdt>
            ; and
         </span>
      </li>
      <li className="MsoNormal"  style={{ lineHeight: "1.5" }}>
         <span style={{ fontSize: "15px" }}>
            warrant and represent that your Submissions
            <bdt className="block-component"></bdt>
            do not constitute confidential information.
         </span>
      </li>
   </ul>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      You are solely responsible for your Submissions
      <bdt className="block-component"></bdt>
      and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable law.
      <bdt className="block-component"></bdt>
      <bdt className="block-component"></bdt>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
</div>
<div align="center" style={{ textAlign: "left" }}>
   <div className="MsoNormal" data-custom-class="heading_1" id="userreps" style={{ lineHeight: "1.5" }}><strong><span style={{ lineHeight: "115%", fontFamily: "Arial",  }}>3. USER REPRESENTATIONS</span></strong></div>
</div>
<div align="center" style={{ lineHeight: "1.5" }}><br/></div>
<div align="center" style={{ textAlign: "left" }}>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>By using the Services, you represent and warrant that:</span>
      <bdt className="block-container if" data-type="if" id="d2d82ca8-275f-3f86-8149-8a5ef8054af6">
         <bdt data-type="conditional-block">
            <bdt className="block-component" data-record-question-key="user_account_option" data-type="statement"></bdt>
            <bdt data-type="body"><span style={{ fontSize: "11pt" }}>(</span><span style={{ fontSize: "14.6667px" }}>1</span><span style={{ fontSize: "11pt" }}>) all registration information you submit
               will be true, accurate, current, and complete; (</span><span style={{ fontSize: "14.6667px" }}>2</span><span style={{ fontSize: "11pt" }}>) you will maintain the accuracy of such information and promptly update such registration information as necessary;</span>
            </bdt>
         </bdt>
         <bdt className="statement-end-if-in-editor" data-type="close"></bdt>
         &nbsp;
      </bdt>
      <span style={{ fontSize: "11pt" }}>(</span><span style={{ fontSize: "14.6667px" }}>3</span><span style={{ fontSize: "11pt" }}>) you have the legal capacity and you agree to comply with these Legal Terms;</span>
      <bdt className="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
         <bdt data-type="conditional-block">
            <bdt className="block-component" data-record-question-key="user_u13_option" data-type="statement"></bdt>
            <bdt data-type="body"><span style={{ fontSize: "11pt" }}>(</span><span style={{ fontSize: "14.6667px" }}>4</span><span style={{ fontSize: "11pt" }}>) you are not under the age of 13;</span></bdt>
         </bdt>
         <bdt className="statement-end-if-in-editor" data-type="close"></bdt>
         &nbsp;
      </bdt>
      <span style={{ fontSize: "11pt" }}>(</span><span style={{ fontSize: "14.6667px" }}>5</span>
      <span style={{ fontSize: "11pt" }}>
         ) you are not a
         minor in the jurisdiction in which you reside
         <bdt className="block-container if" data-type="if" id="76948fab-ec9e-266a-bb91-948929c050c9">
            <bdt data-type="conditional-block">
               <bdt className="block-component" data-record-question-key="user_o18_option" data-type="statement"></bdt>
               <bdt data-type="body">, or if a minor, you have
                  received parental permission to use the Services
               </bdt>
            </bdt>
            <bdt className="statement-end-if-in-editor" data-type="close"></bdt>
         </bdt>
         ; (
      </span>
      <span style={{ fontSize: "14.6667px" }}>6</span><span style={{ fontSize: "11pt" }}>) you will not access the Services through automated or non-human means, whether through a bot, script or
      otherwise; (</span><span style={{ fontSize: "14.6667px" }}>7</span>
      <span style={{ fontSize: "11pt" }}>
         ) you will not use the Services for any illegal or 
         <bdt className="block-component"></bdt>
         unauthorized
         <bdt className="statement-end-if-in-editor"></bdt>
         purpose; and (
      </span>
      <span style={{ fontSize: "14.6667px" }}>8</span><span style={{ fontSize: "11pt" }}>) your use of the Services will not violate any applicable law or regulation.</span><span style={{ fontSize: "14.6667px" }}></span>
   </div>
</div>
<div align="center" style={{ lineHeight: "1.5" }}><br/></div>
<div align="center" style={{ textAlign: "left" }}>
   <div className="MsoNormal" style={{ textAlign: "justify", lineHeight: "115%" }}>
      <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
         <div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}><span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).</span></div>
         <div className="MsoNormal" style={{ lineHeight: "1.1", textAlign: "left" }}>
            <bdt className="block-component"></bdt>
         </div>
         <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1" }}>
         <bdt data-type="conditional-block">
            <bdt data-type="body">
               <div className="MsoNormal" data-custom-class="heading_1" id="userreg" style={{ lineHeight: "1.5", textAlign: "left" }}><strong><span style={{ lineHeight: "24.5333px",  }}>4. USER REGISTRATION</span></strong></div>
            </bdt>
         </bdt>
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
      <div className="MsoNormal" style={{ lineHeight: "1" }}>
         <bdt data-type="conditional-block">
            <bdt data-type="body">
               <div className="MsoNormal"  style={{ textAlign: "left", lineHeight: "1.5" }}>
                  <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                     You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
                     <bdt className="statement-end-if-in-editor" data-type="close"></bdt>
                  </span>
               </div>
            </bdt>
         </bdt>
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
         <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
         <span style={{ fontSize: "15px" }}>
            <bdt className="block-component"></bdt>
         </span>
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
      <div className="MsoNormal" data-custom-class="heading_1" id="products" style={{ lineHeight: "1.5", textAlign: "left" }}><span><strong>5. PRODUCTS</strong></span></div>
      <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
      <div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
         <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
         All products are subject to availability
         <bdt className="block-component"></bdt>
         . We reserve the right to discontinue any products at any time for any reason. Prices for all products are subject to change.
         <bdt className="statement-end-if-in-editor"></bdt>
         <bdt className="statement-end-if-in-editor"></bdt>
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
         <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
      </div>
      <div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
   </div>
</div>




<div align="center" style={{ textAlign: "left" }}>
   <div className="MsoNormal" data-custom-class="heading_1" id="purchases" style={{ lineHeight: "1.5" }}>
                        <strong>
                           <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>6.</span>
                       
         &nbsp;PURCHASES AND PAYMENT
      </strong>
   </div>
</div>
<div align="center" style={{ lineHeight: "1.5" }}><br/></div>
<div align="center" style={{ textAlign: "left" }}>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
      <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
   </div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>We accept the following forms of payment:</span>
   </div>
   <div className="MsoNormal" style={{ textAlign: "justify", lineHeight: "115%" }}>
      <div className="MsoNormal" style={{ textAlign: "left", lineHeight: "1" }}><br/></div>
   </div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5", marginLeft: "20px" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         <bdt className="forloop-component"></bdt>
         - &nbsp;
         <bdt className="question">Visa</bdt>
      </span>
   </div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5", marginLeft: "20px" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         <bdt className="forloop-component"></bdt>
         - &nbsp;
         <bdt className="question">Mastercard</bdt>
      </span>
   </div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5", marginLeft: "20px" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         <bdt className="forloop-component"></bdt>
         - &nbsp;
         <bdt className="question">American Express</bdt>
      </span>
   </div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5", marginLeft: "20px" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         <bdt className="forloop-component"></bdt>
         - &nbsp;
         <bdt className="question">Discover</bdt>
      </span>
   </div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5", marginLeft: "20px" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         <bdt className="forloop-component"></bdt>
         - &nbsp;
         <bdt className="question">PayPal</bdt>
      </span>
   </div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5", marginLeft: "20px" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         <bdt className="forloop-component"></bdt>
      </span>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1" }}><span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}><br/></span></div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments shall be&nbsp;
      </span>
      <span style={{ fontSize: "15px", lineHeight: "115%", fontFamily: "Arial" }}>
         in 
         <bdt className="question">US dollars</bdt>
         .
      </span>
   </div>
</div>




<div align="center" style={{ lineHeight: "1.5" }}><br/></div>
<div align="center" style={{ textAlign: "left" }}>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you 
         <bdt className="block-component"></bdt>
         authorize
         <bdt className="statement-end-if-in-editor"></bdt>
         us to charge your chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.
      </span>
   </div>
</div>
<div align="center" style={{ lineHeight: "1.5" }}><br/></div>



            <div align="center" style={{textAlign:"left"}}>
            <div align="center" style={{ lineHeight: "1.5" }}><br/></div>
<div align="center" style={{ textAlign: "left" }}>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole 
         <bdt className="block-component"></bdt>
         judgment
         <bdt className="statement-end-if-in-editor"></bdt>
         , appear to be placed by dealers, resellers, or distributors.
      </span>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
      <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
   <div className="MsoNormal" data-custom-class="heading_1" id="subscriptions" style={{ lineHeight: "1.5" }}><strong><span >7. SUBSCRIPTIONS</span></strong></div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
      <bdt className="block-component"></bdt>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
   <div className="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5" }}><strong><span style={{ fontSize: "15px" }}>Billing and Renewal</span></strong></div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}><span style={{ fontSize: "15px" }}><br/></span></div>
   <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: "15px" }}>
         <bdt className="block-component"></bdt>
         Your subscription will continue and automatically renew unless 
         <bdt className="block-component"></bdt>
         canceled
         <bdt className="statement-end-if-in-editor"></bdt>
         . You consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable order.
         <bdt className="block-component"></bdt>
         The length of your billing cycle 
         <bdt className="block-component"></bdt>
         is monthly
         <bdt className="block-component"></bdt>
         .
         <bdt className="statement-end-if-in-editor"></bdt>
         <bdt className="else-block"></bdt>
      </span>
   </div>
   <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
      <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
      <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
   </div>
</div>



<div class="MsoNormal" style={{ lineHeight: "1.5" }}><span style={{ fontSize: "15px" }}><br/></span></div>
<div class="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5" }}><span style={{ fontSize: "15px" }}><strong>Cancellation</strong></span></div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
   <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
   <bdt className="block-component"></bdt>
   You can cancel your subscription at any time by logging into your account.
   <bdt className="block-component"></bdt>
   Your cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied with our Services, please email us at 
   <bdt className="question"><a href="mailto:somos@somosblockchain.com">somos@somosblockchain.com</a></bdt>
   .
   <bdt className="statement-end-if-in-editor"></bdt>
   <br/>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5" }}><strong><span style={{ fontSize: "15px" }}>Fee Changes</span></strong></div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><span style={{ fontSize: "15px" }}><br/></span></div>
<span style={{ fontSize: "15px" }}><span >We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with applicable law.</span></span>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
   <span style={{ fontSize: "15px" }}>
      <bdt className="statement-end-if-in-editor"></bdt>
   </span>
   <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
   <span style={{ fontSize: "15px" }}>
      <bdt className="block-component"></bdt>
   </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" data-custom-class="heading_1" id="returnno" style={{ lineHeight: "1.5" }}>
   <strong>
      <span >
         8. REFUNDS POLICY
      </span>
   </strong>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
   <span style={{ fontSize: "15px" }}>
      All sales are final and no refund will be issued.
      <bdt className="statement-end-if-in-editor"></bdt>
      <bdt className="statement-end-if-in-editor"></bdt>
   </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
   <bdt className="block-component"></bdt>
</div>
<div class="MsoNormal" style={{ textAlign: "justify", lineHeight: "1.5" }}>
   <div class="MsoNormal" data-custom-class="heading_1" id="prohibited" style={{ textAlign: "left", lineHeight: "1.5" }}>
   <div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <strong>
        <span>9. PROHIBITED ACTIVITIES</span>
    </strong></div>
   </div>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" style={{ textAlign: "justify", lineHeight: "1" }}>
   <div class="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
      <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
         You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial 
         <bdt className="block-component"></bdt>
         endeavors
         <bdt className="statement-end-if-in-editor"></bdt>
         except those that are specifically endorsed or approved by us.
      </span>
   </div>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>



               <div class="MsoNormal" style={{textAlign:"justify",lineHeight:'1'}}>

               <div className="MsoNormal" style={{ lineHeight: '17.25px' }}>
                <div className="MsoNormal" style={{ lineHeight: '1.1' }}>
                    <div className="MsoNormal" style={{ lineHeight: '17.25px' }}>
                        <div className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
                            <span style={{ fontSize: '11pt', lineHeight: '16.8667px' }}>As a user of the Services, you agree not to:</span>
                        </div>
                    </div>

                        <ul>
                          <li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '11pt', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontSize: '15px', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Use any information obtained from the Services in order to harass, abuse, or harm another person.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Make improper use of our support services or submit false reports of abuse or misconduct.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Use the Services in a manner inconsistent with any applicable laws or regulations.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Engage in unauthorized framing of or linking to the Services.
    </span>
  </span>
</li>


<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Delete the copyright or other proprietary rights notice from any Content.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Attempt to impersonate another user or person or use the username of another user.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or "passive collection mechanisms" or "pcms").
    </span>
  </span>
</li>


<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any unauthorized script or other software.
    </span>
  </span>
</li>

<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Use a buying agent or purchasing agent to make purchases on the Services.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.
    </span>
  </span>
</li>
<li className="MsoNormal"  style={{ lineHeight: '1.5', textAlign: 'left' }}>
  <span style={{ fontSize: '15px', lineHeight: '16.8667px' }}>
    <span style={{ fontFamily: 'sans-serif', fontStyle: 'normal', fontVariantLigatures: 'normal', fontVariantCaps: 'normal', fontWeight: 400, letterSpacing: 'normal', orphans: 2, textAlign: 'justify', textIndent: '-29.4px', textTransform: 'none', whiteSpace: 'normal', widows: 2, wordSpacing: '0px', WebkitTextStrokeWidth: '0px', textDecorationStyle: 'initial', textDecorationColor: 'initial' }}>
      Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavor.
    </span>
  </span>
</li>

                        </ul>
                     </div>
                     <div class="MsoNormal" style={{lineHeight:"1.5",textAlign:"left"}}><br/></div>
                  </div>

                  
                  <div class="MsoNormal" style={{lineHeight:"17.25px"}}>
                   <div className="MsoNormal" style={{ lineHeight: "1" }}>
  <div className="block-container if" data-type="if" style={{ textAlign: "left" }}>
    <div data-type="conditional-block">
      <div data-custom-class="heading_1" id="ugc" style={{ lineHeight: "1.5" }}>
        <strong><span style={{ lineHeight: "24.5333px",  }}>
          10. USER GENERATED CONTRIBUTIONS
            </span></strong>
      </div>
    </div>
  </div>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
  <br />
</div>
<div className="MsoNormal" style={{ lineHeight: "1" }}>
  <div className="block-container if" data-type="if" style={{ textAlign: "left" }}>
    <div data-type="conditional-block">
      <div className="MsoNormal"  style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
          <div className="block-container if" data-type="if" id="24327c5d-a34f-f7e7-88f1-65a2f788484f" style={{ textAlign: "left" }}>
            <div data-type="conditional-block">
              <div className="block-component" data-record-question-key="user_post_content_option" data-type="statement"></div>
              <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                The Services does not offer users to submit or post content.
                <div className="block-component"></div>
                We may provide you with the opportunity to create, submit, post, display, 
                transmit, perform, publish, distribute, or broadcast content and materials 
                to us or on the Services, including but not limited to text, writings, video,
                 audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively,"Contributions"). Contributions may be viewable by other users of the Services and through third-party websites.
                When you create or make available any Contributions, you thereby represent and warrant that:
              </span>
            </div>
          </div>
        </span>
      </div>
    </div>
    &nbsp;
  </div>
</div>

                     <ul style={{fontSize:'medium',textAlign:"left"}}>
                     <li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      You are the creator and owner of or have the necessary
      <bdt class="block-component"></bdt>
      licenses
      <bdt class="statement-end-if-in-editor"></bdt>
      , rights, consents, releases, and permissions to use and to
      <bdt class="block-component"></bdt>
      authorize
      <bdt class="statement-end-if-in-editor"></bdt>
      us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions are not false, inaccurate, or misleading.
    </span>
    &nbsp;
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions are not unsolicited or
      <bdt class="block-component"></bdt>
      unauthorized
      <bdt class="statement-end-if-in-editor"></bdt>
      advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing,
      <bdt class="block-component"></bdt>
      libelous
      <bdt class="statement-end-if-in-editor"></bdt>
      , slanderous, or otherwise objectionable (as determined by us).
    </span>
    &nbsp;
  </span>
</li>

<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions do not violate any applicable law, regulation, or rule.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions do not violate the privacy or publicity rights of any third party.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.
    </span>
  </span>
</li>
<li  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "14px" }}>
    <span >
      Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.
    </span>
  </span>
</li>

                     </ul>
                  </div>
               </div>

               <div class="MsoNormal" style={{ textAlign: "justify", lineHeight: "1.5" }}>
  <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
    <bdt data-type="conditional-block">
      <bdt data-type="body">
        <div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension of your rights to use the Services.</span>
        </div>
      </bdt>
    </bdt>
  </bdt>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
<div class="MsoNormal" style={{ textAlign: "justify", lineHeight: "1" }}>
  <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
    <bdt data-type="conditional-block">
      <bdt data-type="body">
        <div class="MsoNormal" data-custom-class="heading_1" id="license" style={{ lineHeight: "1.5" }}>
          <strong>
            <span style={{ lineHeight: "24.5333px",  }}>
            11. CONTRIBUTION LICENSE
            </span>
          </strong>
        </div>
      </bdt>
    </bdt>
  </bdt>
</div>

<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br /></div>
<div class="MsoNormal" style={{ lineHeight: "1" }}>
  <bdt class="block-container if" data-type="if" id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709" style={{ textAlign: "left" }}>
    <bdt data-type="conditional-block">
      <bdt class="block-component" data-record-question-key="user_post_content_option" data-type="statement"></bdt>
    </bdt>
  </bdt>
</div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
  <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
      You and Services agree that we may access, store, process, and use any information and personal data that you provide
      <bdt class="block-component"></bdt>
      and your choices (including settings).
    </span>
  </span>
</div>


<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.
        <bdt class="block-component"></bdt>
    </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
        <bdt class="statement-end-if-in-editor"></bdt>
    </span>
</div>

<div class="MsoNormal"  style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
                    <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                </bdt>
            </span>
        </span>
    </span>
</div>

<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="review_option" data-type="statement"></bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="mobile_app_option" data-type="statement"></bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" data-custom-class="heading_1" id="mobile" style={{ lineHeight: "1.5" }}>
    <strong>
        <span style={{ lineHeight: "24.5333px",  }}>
            12. MOBILE APPLICATION LICENSE
            </span>
    </strong>
</div>

<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5" }}>
    <strong>
        Use 
        <bdt class="block-component"></bdt>
        License
        <bdt class="statement-end-if-in-editor"></bdt>
    </strong>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on wireless electronic devices owned or controlled by you, and to access and use the App on such devices strictly in accordance with the terms and conditions of this mobile application 
        <bdt class="block-component"></bdt>
        license
        <bdt class="statement-end-if-in-editor"></bdt>
        contained in these Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the App; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App; (3) violate any applicable laws, rules, or regulations in connection with your access or use of the App; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the App; (5) use the App for any revenue-generating 
        <bdt class="block-component"></bdt>
        endeavor
        <bdt class="statement-end-if-in-editor"></bdt>
        , commercial enterprise, or other purpose for which it is not designed or intended; (6) make the App available over a network or other environment permitting access or use by multiple devices or users at the same time; (7) use the App for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the App; (8) use the App to send automated queries to any website or to send any unsolicited commercial email; or (9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the App.
    </span>
</div>


<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5" }}><span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}><strong>Apple and Android Devices</strong></span></div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        The following terms apply when you use the App obtained from either the Apple Store or Google Play (each an 
        <bdt class="block-component"></bdt>
        "App Distributor"
        <bdt class="statement-end-if-in-editor"></bdt>
        ) to access the Services: (1) the 
        <bdt class="block-component"></bdt>
        license
        <bdt class="statement-end-if-in-editor"></bdt>
        granted to you for our App is limited to a non-transferable 
        <bdt class="block-component"></bdt>
        license
        <bdt class="statement-end-if-in-editor"></bdt>
        to use the application on a device that 
        <bdt class="block-component"></bdt>
        utilizes
        <bdt class="statement-end-if-in-editor"></bdt>
        the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s terms of service; (2) we are responsible for providing any maintenance and support services with respect to the App as specified in the terms and conditions of this mobile application 
        <bdt class="block-component"></bdt>
        license
        <bdt class="statement-end-if-in-editor"></bdt>
        contained in these Legal Terms or as otherwise required under applicable law, and you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect to the App; (3) in the event of any failure of the App to conform to any applicable warranty, you may notify the applicable App Distributor, and the App Distributor, in accordance with its terms and policies, may refund the purchase price, if any, paid for the App, and to the maximum extent permitted by applicable law, the App Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent and warrant that (i) you are not located in a country that is subject to a US government embargo, or that has been designated by the US government as a 
        <bdt class="block-component"></bdt>
        "terrorist supporting"
        <bdt class="statement-end-if-in-editor"></bdt>
        country and (ii) you are not listed on any US government list of prohibited or restricted parties; (5) you must comply with applicable third-party terms of agreement when using the App, e.g.
        <bdt class="block-component"></bdt>
        ,
        <bdt class="statement-end-if-in-editor"></bdt>
        if you have a VoIP application, then you must not be in violation of their wireless data service agreement when using the App; and (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and conditions in this mobile application 
        <bdt class="block-component"></bdt>
        license
        <bdt class="statement-end-if-in-editor"></bdt>
        contained in these Legal Terms, and that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile application 
        <bdt class="block-component"></bdt>
        license
        <bdt class="statement-end-if-in-editor"></bdt>
        contained in these Legal Terms against you as a third-party beneficiary thereof.
    </span>
</div>

<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
                    <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                </bdt>
            </span>
        </span>
    </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="socialnetwork_link_option" data-type="statement"></bdt>
        </bdt>
    </bdt>
</div>

            </div>


            <div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="3rd_party_option" data-type="statement"></bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" data-custom-class="heading_1" id="thirdparty" style={{ lineHeight: "1.5" }}>
    <strong>
        <span style={{ lineHeight: "24.5333px",}}>
        13. THIRD-PARTY WEBSITES AND CONTENT
        </span>
    </strong>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        The Services may contain (or you may be sent via the 
        <bdt class="block-component"></bdt> App
        <bdt class="statement-end-if-in-editor"></bdt>) links to other websites (
        <bdt class="block-component"></bdt> "Third-Party Websites"
        <bdt class="statement-end-if-in-editor"></bdt>) as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties (
        <bdt class="block-component"></bdt> "Third-Party Content"
        <bdt class="statement-end-if-in-editor"></bdt>). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through the Services or any Third-Party Content posted on, available through, or installed from the Services, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Legal Terms no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Services or relating to any applications you use or install from the Services. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party. You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm caused by your purchase of such products or services. Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
    </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
    </bdt>
</div>

<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        . If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        , then through your continued use of the Services, you are transferring your data to 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        , and you expressly consent to have your data transferred to and processed in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        .
        <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
            <bdt data-type="conditional-block">
                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                    <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                    <bdt class="block-component"></bdt>
                                    <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
                                        <bdt data-type="conditional-block">
                                            <bdt class="block-component" data-record-question-key="user_u13_option" data-type="statement"></bdt>
                                            <bdt data-type="body">&nbsp;Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Services as quickly as is reasonably practical.</bdt>
                                        </bdt>
                                        <bdt class="statement-end-if-in-editor" data-type="close">
                                            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </span>
                            </bdt>
                        </bdt>
                    </bdt>
                </span>
            </bdt>
        </bdt>
    </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                    <bdt class="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                    <bdt class="block-container if" data-type="if">
                                        <bdt class="statement-end-if-in-editor" data-type="close">
                                            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                <bdt class="statement-end-if-in-editor">
                                                    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                        <bdt class="block-container if" data-type="if">
                                                            <bdt data-type="conditional-block">
                                                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                                    <bdt class="block-container if" data-type="if">
                                                                        <bdt data-type="conditional-block">
                                                                            <bdt data-type="body">
                                                                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                                                    <bdt class="block-container if" data-type="if">
                                                                                        <bdt class="statement-end-if-in-editor" data-type="close">
                                                                                            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                                                            </span>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </bdt>
                                                                </span>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </span>
                            </bdt>
                        </bdt>
                    </bdt>
                </span>
            </bdt>
        </bdt>
    </span>
</div>

<div class="MsoNormal"  style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        . If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        , then through your continued use of the Services, you are transferring your data to 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        , and you expressly consent to have your data transferred to and processed in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        .
        <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
            <bdt data-type="conditional-block">
                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                    <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                    <bdt class="block-component"></bdt>
                                    <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
                                        <bdt data-type="conditional-block">
                                            <bdt class="block-component" data-record-question-key="user_u13_option" data-type="statement"></bdt>
                                            <bdt data-type="body">&nbsp;Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Services as quickly as is reasonably practical.</bdt>
                                        </bdt>
                                        <bdt class="statement-end-if-in-editor" data-type="close">
                                            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </span>
                            </bdt>
                        </bdt>
                    </bdt>
                </span>
            </bdt>
        </bdt>
    </span>
</div>

            <div class="MsoNormal" style={{lineHeight:"1.5"}}><br/></div>
            <div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                    <bdt class="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                    <bdt class="block-container if" data-type="if">
                                        <bdt class="statement-end-if-in-editor" data-type="close">
                                            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                <bdt class="statement-end-if-in-editor">
                                                    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                        <bdt class="block-container if" data-type="if">
                                                            <bdt data-type="conditional-block">
                                                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                                    <bdt class="block-container if" data-type="if">
                                                                        <bdt data-type="conditional-block">
                                                                            <bdt data-type="body">
                                                                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                                                    <bdt class="block-container if" data-type="if">
                                                                                        <bdt class="statement-end-if-in-editor" data-type="close">
                                                                                            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                                                            </span>
                                                                                        </bdt>
                                                                                    </bdt>
                                                                                </span>
                                                                            </bdt>
                                                                        </bdt>
                                                                    </bdt>
                                                                </span>
                                                            </bdt>
                                                        </bdt>
                                                    </span>
                                                </bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </span>
                            </bdt>
                        </bdt>
                    </bdt>
                </span>
            </bdt>
        </bdt>
    </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="copyright_agent_option" data-type="statement">
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt>
            </bdt>
            <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
                <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
            </bdt>
        </bdt>
    </bdt>
</div>


<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="advertiser_option" data-type="statement"></bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" data-custom-class="heading_1" id="sitemanage" style={{ lineHeight: "1.5" }}>
    <strong>
        <span style={{ lineHeight: "24.5333px"}}>
       14. SERVICES MANAGEMENT
        </span>
    </strong>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
    We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt data-type="conditional-block">
            <bdt class="block-component" data-record-question-key="privacy_policy_option" data-type="statement"></bdt>
        </bdt>
        <bdt class="block-container if" data-type="if">
            <bdt data-type="conditional-block">
                <bdt class="block-component" data-record-question-key="privacy_policy_followup" data-type="statement" style={{ fontSize: "14.6667px" }}></bdt>
            </bdt>
        </bdt>
    </bdt>
</div>
<div class="MsoNormal" data-custom-class="heading_1" id="ppno" style={{ lineHeight: "1.5" }}>
    <strong>
        <span style={{ lineHeight: "24.5333px",  }}>
            15. PRIVACY POLICY
        </span>
    </strong>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5" }}><br/></div>
<div class="MsoNormal" data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        . If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        , then through your continued use of the Services, you are transferring your data to 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        , and you expressly consent to have your data transferred to and processed in 
        <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
            <bdt class="block-component"></bdt>
            the 
            <bdt class="question">United States</bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="block-component"></bdt>
        .
        <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
            <bdt data-type="conditional-block">
                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                    <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
                        <bdt data-type="conditional-block">
                            <bdt data-type="body">
                                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                    <bdt class="block-component"></bdt>
                                    <bdt class="block-container if" data-type="if" id="547bb7bb-ecf2-84b9-1cbb-a861dc3e14e7">
                                        <bdt data-type="conditional-block">
                                            <bdt class="block-component" data-record-question-key="user_u13_option" data-type="statement"></bdt>
                                            <bdt data-type="body">&nbsp;Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Services as quickly as is reasonably practical.</bdt>
                                        </bdt>
                                        <bdt class="statement-end-if-in-editor" data-type="close">
                                            <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span>
                                        </bdt>
                                    </bdt>
                                </span>
                            </bdt>
                        </bdt>
                    </bdt>
                </span>
            </bdt>
        </bdt>
    </span>
</div>

<div class="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <bdt class="block-component"></bdt>
    <bdt class="block-container if" data-type="if" style={{ textAlign: "left" }}>
        <bdt class="statement-end-if-in-editor" data-type="close">
            <bdt class="block-component"></bdt>
        </bdt>
        <bdt class="block-component"></bdt><br/>
    </bdt>
</div>

<div class="MsoNormal" data-custom-class="heading_1" id="terms" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong>
        <span style={{ lineHeight: "24.5333px",  }}>
            16. TERM AND TERMINATION
           </span>
       
    </strong>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div class="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE 
        <bdt class="block-container if" data-type="if" id="a6e121c2-36b4-5066-bf9f-a0a33512e768">
            <bdt data-type="conditional-block">
                <bdt class="block-component" data-record-question-key="user_account_option" data-type="statement"></bdt>
                <bdt data-type="body">YOUR ACCOUNT AND&nbsp;</bdt>
            </bdt>
            <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
        </bdt>
        ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
    </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div class="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
    </span>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div class="MsoNormal" data-custom-class="heading_1" id="modifications" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong>
        <span style={{ lineHeight: "24.5333px"}}>
        17. MODIFICATIONS AND INTERRUPTIONS
        </span>&nbsp;
        
    </strong>
</div>
<div class="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div class="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services.
        <bdt class="block-component"></bdt>
        We also reserve the right to modify or discontinue all or part of the Services without notice at any time.
        <bdt class="statement-end-if-in-editor"></bdt>
        We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
    </span>
</div>

<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.</span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="law" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ lineHeight: "24.5333px",  }}>18. GOVERNING LAW</span>&nbsp;</strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        <bdt className="block-component"></bdt>
    </span>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
        These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of
        <bdt className="block-container if" data-type="if" id="b86653c1-52f0-c88c-a218-e300b912dd6b">
            <bdt data-type="conditional-block">
                <bdt className="block-component" data-record-question-key="governing_law" data-type="statement"></bdt>
                <bdt data-type="body">
                    the State of
                    <bdt className="block-container question question-in-editor" data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3" data-type="question">Delaware</bdt>
                </bdt>
            </bdt>
            <bdt className="statement-end-if-in-editor" data-type="close"></bdt>
        </bdt>
        applicable to agreements made and to be entirely performed within
        <bdt className="block-container if" data-type="if" id="b86653c1-52f0-c88c-a218-e300b912dd6b" style={{ fontSize: "14.6667px" }}>
            <bdt data-type="conditional-block">
                &nbsp;
                <span style={{ fontSize: "11pt", lineHeight: "16.8667px" }}>
                    <bdt className="block-container if" data-type="if" id="b86653c1-52f0-c88c-a218-e300b912dd6b">
                        <bdt data-type="conditional-block">
                            <bdt className="block-component" data-record-question-key="governing_law" data-type="statement"></bdt>
                            <bdt data-type="body">
                                the State of
                                <bdt className="block-container question question-in-editor" data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3" data-type="question">Delaware</bdt>
                            </bdt>
                        </bdt>
                        <bdt className="statement-end-if-in-editor" data-type="close"></bdt>
                    </bdt>
                    <span style={{ fontSize: "14.6667px" }}>,&nbsp;</span>without regard to its conflict of law principles.
                    <bdt className="block-component"></bdt>
                </span>
            </bdt>
        </bdt>
    </span>
</div>

<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="disputes" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ lineHeight: "24.5333px", }}>19. DISPUTE RESOLUTION</span>&nbsp;</strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <bdt className="block-component"></bdt>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "15px" }}>
        Any legal action of whatever nature brought by either you or us (collectively, the 
        <bdt className="block-component"></bdt>
        "Parties" and individually, a "Party"
        <bdt className="statement-end-if-in-editor"></bdt>
        ) shall be commenced or prosecuted in the
        <bdt className="block-component"></bdt>
        state and federal courts
        <bdt className="statement-end-if-in-editor"></bdt>
        located in
        <bdt className="block-component"></bdt>
        <bdt className="question">Kent</bdt>
        ,
        <bdt className="statement-end-if-in-editor"></bdt>
        <bdt className="block-component"></bdt>
        <bdt className="question">Delaware</bdt>
        <bdt className="statement-end-if-in-editor"></bdt>
        , and the Parties hereby consent to, and waive all 
        <bdt className="block-component"></bdt>
        defenses
        <bdt className="statement-end-if-in-editor"></bdt>
        of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in such
        <bdt className="block-component"></bdt>
        state and federal courts
        <bdt className="statement-end-if-in-editor"></bdt>
        . Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these Legal Terms. 
        <bdt className="block-component"></bdt>
        In no event shall any claim, action, or proceeding brought by either Party related in any way to the Services be commenced more than 
        <bdt className="question">one (1)</bdt>
        years after the cause of action arose.
    </span>
    <bdt className="statement-end-if-in-editor">
        <span style={{ fontSize: "15px" }}></span>
        <bdt className="block-component"></bdt>
    </bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <bdt className="statement-end-if-in-editor">
        <bdt className="else-block"></bdt>
    </bdt>
</div>

<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="corrections" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span >20. CORRECTIONS</span>&nbsp;</strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="disclaimer" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{  color: "black" }}><strong><span >21. DISCLAIMER</span>&nbsp;</strong></span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial,Calibri",  msoThemeColor: "text1", msoThemeTint: "166" }}>
        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY 
        <bdt className="block-component"></bdt>
        UNAUTHORIZED
        <bdt className="statement-end-if-in-editor"></bdt>
        ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST 
        <bdt className="block-component"></bdt>
        JUDGMENT
        <bdt className="statement-end-if-in-editor"></bdt>
        AND EXERCISE CAUTION WHERE APPROPRIATE.
    </span>
</div>

<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="liability" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ lineHeight: "115%", fontFamily: "Arial", }}>22. LIMITATIONS OF LIABILITY</span>&nbsp;</strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial,Calibri",  msoThemeColor: "text1", msoThemeTint: "166" }}>
        <span className="body_text">IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span>
        <bdt className="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
            <span className="body_text">
                <bdt data-type="conditional-block">
                    <bdt className="block-component" data-record-question-key="limitations_liability_option" data-type="statement"></bdt>
                    <bdt data-type="body">
                        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO 
                        <bdt className="block-container if" data-type="if" id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                            <bdt data-type="conditional-block">
                                <bdt className="block-component" data-record-question-key="limitations_liability_option" data-type="statement"></bdt>
                                <bdt data-type="body">
                                    THE
                                    AMOUNT PAID, IF ANY, BY YOU TO US
                                    <bdt className="block-container if" data-type="if" id="19e172cb-4ccf-1904-7c06-4251800ba748">
                                        <bdt data-type="conditional-block">
                                            &nbsp;
                                            <span style={{ fontSize: "11pt", textTransform: "uppercase" }}>
                                                DURING THE 
                                                <bdt className="block-container question question-in-editor" data-id="5dd68d46-ed6f-61c7-cd66-6b3f424b6bdd" data-type="question">one (1)</bdt>
                                                MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING
                                            </span>
                                        </bdt>
                                        <bdt className="statement-end-if-in-editor" data-type="close"></bdt>
                                    </bdt>
                                </bdt>
                            </bdt>
                            <bdt data-type="conditional-block">
                                <bdt className="block-component" data-record-question-key="limitations_liability_option" data-type="statement">.</bdt>
                            </bdt>
                        </bdt>
                    </bdt>
                </bdt>
            </span>
        </bdt>
        &nbsp;
    </span>
    <span className="body_text">CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</span>
    <bdt className="statement-end-if-in-editor" data-type="close"><span className="body_text"></span></bdt>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="indemnification" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ lineHeight: "115%", fontFamily: "Arial",  }}>23. INDEMNIFICATION</span>&nbsp;</strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial,Calibri",  msoThemeColor: "text1", msoThemeTint: "166" }}>
        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: 
        <bdt className="block-container if" data-type="if" id="475fffa5-05ca-def8-ac88-f426b238903c">
            <bdt data-type="conditional-block">
                <bdt className="block-component" data-record-question-key="user_post_content_option" data-type="statement"></bdt>
            </bdt>
            (<span style={{ fontSize: "14.6667px" }}>1</span>) use of the Services; (<span style={{ fontSize: "14.6667px" }}>2</span>) breach of these Legal Terms; (<span style={{ fontSize: "14.6667px" }}>3</span>) any breach of your representations and warranties set forth in these Legal Terms; (<span style={{ fontSize: "14.6667px" }}>4</span>) your violation of the rights of a third party, including but not limited to intellectual property rights; or (<span style={{ fontSize: "14.6667px" }}>5</span>) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive 
            <bdt className="block-component"></bdt>
            defense
            <bdt className="statement-end-if-in-editor"></bdt>
            and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our 
            <bdt className="block-component"></bdt>
            defense
            <bdt className="statement-end-if-in-editor"></bdt>
            of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
        </bdt>
    </span>
</div>

<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="userdata" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ lineHeight: "115%", fontFamily: "Arial", }}>24. USER DATA</span>&nbsp;</strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial,Calibri",  msoThemeColor: "text1", msoThemeTint: "166" }}>
        We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
    </span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="electronic" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ lineHeight: "115%", fontFamily: "Arial",  }}>25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span>&nbsp;</strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial,Calibri",  msoThemeColor: "text1", msoThemeTint: "166" }}>
        Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
    </span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
    <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
</div>

         <div className="MsoNormal" data-custom-class="heading_1" id="sms" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span ><strong>26. SMS TEXT MESSAGING</strong></span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "15px" }}>
        <bdt className="block-component"></bdt>
    </span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ fontSize: "15px" }}>Opting Out</span></strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "15px" }}><br/></span>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "15px" }}>
        <bdt className="block-component"></bdt>
        <span style={{ fontSize: "15px" }}>If at any time you wish to stop receiving SMS messages from us, simply reply to the text with "STOP.” You may receive an SMS message confirming your opt out.</span>
        <bdt className="else-block"></bdt>
    </span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ fontSize: "15px" }}>Message and Data Rates</span></strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "15px" }}>Please be aware that message and data rates may apply to any SMS messages sent or received. The rates are determined by your carrier and the specifics of your mobile plan.</span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>
<div className="MsoNormal" data-custom-class="heading_2" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ fontSize: "15px" }}>Support</span></strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <br/>
</div>

         
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "15px" }}>
        If you have any questions or need assistance regarding our SMS communications, please email us at 
        <bdt className="question"><a href="mailto:somos@somosblockchain.com">somos@somosblockchain.com</a></bdt>
        <bdt className="block-component"></bdt>
        or call at 
        <bdt className="question">1-800-475-0691</bdt>
        <bdt className="statement-end-if-in-editor"></bdt>
        .<br/>
    </span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <span style={{ fontSize: "15px" }}>
        <bdt className="statement-end-if-in-editor"></bdt>
    </span>
    <bdt className="block-component"></bdt>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="california" style={{ lineHeight: "1.5", textAlign: "left" }}>
    <strong><span style={{ lineHeight: "115%", fontFamily: "Arial", }}>
    27. CALIFORNIA USERS AND RESIDENTS
    </span></strong></div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}><span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.</span></div>


<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
   <bdt className="statement-end-if-in-editor"></bdt>
</div>
<div className="MsoNormal" data-custom-class="heading_1" id="misc" style={{ lineHeight: "1.5", textAlign: "left" }}>
   <strong><span style={{ lineHeight: "115%", fontFamily: "Arial",  }}>
   28. MISCELLANEOUS
      </span>&nbsp;
   </strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
   <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
      These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all 
      <bdt className="block-component"></bdt>
      defenses
      <bdt className="statement-end-if-in-editor"></bdt>
      you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
   </span>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}>
   <bdt className="block-component"><span style={{ fontSize: "15px" }}></span></bdt>
</div>

<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
<div className="MsoNormal" data-custom-class="heading_1" id="contact" style={{ lineHeight: "1.5", textAlign: "left" }}>
   <strong><span style={{ lineHeight: "115%", fontFamily: "Arial" }}>29. CONTACT US</span></strong>
</div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}><span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:</span></div>
<div className="MsoNormal" style={{ lineHeight: "1.5", textAlign: "left" }}><br/></div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
   <span style={{ fontSize: "15px" }}>
      <span style={{}}>
         <bdt className="question"><strong>Somos Blockchain, INC</strong></bdt>
         <strong>
            <bdt className="block-component"></bdt>
         </strong>
      </span>
   </span>
</div>

<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
   <span style={{ fontSize: "15px" }}>
      <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
         <bdt className="question">
            <strong>
               <bdt className="question">8 The Green</bdt>
            </strong>
         </bdt>
         <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
            <bdt className="statement-end-if-in-editor"></bdt>
         </span>
         <bdt className="block-component"></bdt>
      </span>
   </span>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
   <span style={{ fontSize: "15px" }}>
      <strong>
         <span style={{}}>
            <bdt className="question">
               <bdt className="block-component"></bdt>
               <bdt className="question">Dover</bdt>
               <bdt className="statement-end-if-in-editor"></bdt>
            </bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            , 
            <bdt className="question">DE</bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="block-component"></bdt>
            <bdt className="question">19901</bdt>
            <bdt className="statement-end-if-in-editor"></bdt>
         </span>
      </strong>
      <strong>
         <span style={{}}>
            <bdt className="block-component"></bdt>
         </span>
         <bdt className="block-component"></bdt>
         <bdt className="block-component"></bdt>
      </strong>
   </span>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
   <span style={{ fontSize: "15px" }}>
      <bdt className="question"><strong>United States</strong></bdt>
      <strong>
         <bdt className="statement-end-if-in-editor">
            <span style={{}}>
               <strong>
                  <span style={{ fontSize: "15px" }}>
                     <bdt className="statement-end-if-in-editor">
                        <span style={{}}>
                           <strong>
                              <span style={{ fontSize: "15px" }}>
                                 <bdt className="statement-end-if-in-editor">
                                    <bdt className="block-component"></bdt>
                                 </bdt>
                              </span>
                           </strong>
                           <bdt className="statement-end-if-in-editor"><strong></strong></bdt>
                        </span>
                     </bdt>
                  </span>
               </strong>
            </span>
         </bdt>
      </strong>
   </span>
</div>

<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
   <strong>
      <strong>
         <strong>
            <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
               <strong>
                  <bdt className="block-component"></bdt>
                  Phone: 
                  <bdt className="question">1-800-475-0691</bdt>
                  <bdt className="statement-end-if-in-editor"></bdt>
               </strong>
            </span>
            &nbsp;
         </strong>
      </strong>
   </strong>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
   <strong>
      <strong>
         <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
            <strong>
               <bdt className="block-component"></bdt>
            </strong>
         </span>
      </strong>
   </strong>
</div>
<div className="MsoNormal"  style={{ lineHeight: "1.5", textAlign: "left" }}>
   <strong>
      <span style={{ fontSize: "11.0pt", lineHeight: "115%", fontFamily: "Arial, Calibri",  msoThemecolor: "text1", msoThemetint: "166" }}>
         <strong>
            <bdt className="question">
               <bdt className="block-component"></bdt>
               <bdt className="question"><a href="mailto:somos@somosblockchain.com">somos@somosblockchain.com</a></bdt>
               <bdt className="statement-end-if-in-editor"></bdt>
            </bdt>
         </strong>
      </span>
   </strong>
</div>

            </div>
         </div>
      </div>
    </>
  );
};

export default TermsAndCondtions;
