import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import { useSpring, animated } from '@react-spring/web';

const Section4 = ({innerWidth,settings}) => {
  const ref = useRef();
  const [leftCardStyles, leftCardApi] = useSpring(() => ({ transform: 'translateX(60%) rotate(-15deg)',x: 120, opacity: 0, config: { duration: 1000 } }));
  const [middleCardStyles, middleCardApi] = useSpring(() => ({ opacity: 0, config: { duration: 1000 } }));
  const [rightCardStyles, rightCardApi] = useSpring(() => ({ transform: 'translateX(-60%) rotate(15deg)',x: -120, opacity: 0, config: { duration: 1000 } }));


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            leftCardApi.start({transform: 'translateX(60%) rotate(0deg)', x: -280, opacity: 1,zIndex:'1' });
            middleCardApi.start({ opacity: 1 });
            rightCardApi.start({ transform: 'translateX(-60%) rotate(0deg)',x: 280, opacity: 1,zIndex:'1' });
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [leftCardApi, middleCardApi, rightCardApi]);
  return (
    <>
        <div  id="plan" className={`w-full ${innerWidth<=1025?"min-h-[600px]":"min-h-[1000px]"} lg:min-h-[800px]   relative bg-neutral-950 rounded-[65px]`}>
      <div
          className="w-full max-w-[400px] h-[400px]    absolute"
          style={{
            borderRadius: "386px",
            background: "rgba(254, 144, 82, 0.25)",
            filter: "blur(100px)",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop:"90px"
          }}></div>
<div className="pt-16  justify-center items-center gap-2.5 " >
<div className="text-center text-zinc-100 text-[50px] lg:text-[80px] font-normal bebas-neue-regular">Choose your plan</div>
</div>
{
  innerWidth<=1280?
  <>
  
  <Slider {...settings}>
  <>
<div className="w-full max-w-[333px] gradient-box   h-[400px] lg:h-[430px]  2xl:h-[460px] p-6  bg-[#151313] rounded-[32px]  flex-col justify-start items-center gap-10 inline-flex">
    <div className="text-zinc-100  text-5xl   font-medium  ">Plan 1</div>
    <div><span className="gradient-text-price text-[50px] xl:text-[80px] font-semibold  ">$24.99</span><span className="text-indigo-500 text-2xl font-semibold  ">/month</span></div>
    <div className=" text-center text-zinc-300 px-4 text-[15px]  lg:text-xl font-medium  ">Receives a minimum of 80% of the monthly membership in crypto monthly</div>
    <div className="border-wrap rounded-full">
    <button className="gradient-btn px-8 py-4 rounded-[32px] module justify-center items-center gap-2.5 inline-flex">
        <div className=" text-xl font-semibold  ">Buy now</div>
    </button>
    </div>
</div>
</>

<>
<div className="w-full max-w-[333px] gradient-box   h-[400px] lg:h-[430px]  2xl:h-[460px] p-6  bg-[#151313] rounded-[32px]  flex-col justify-start items-center gap-10 inline-flex">
    <div className="text-zinc-100  text-5xl   font-medium  ">Plan 2</div>
    <div><span className="gradient-text-price text-[50px] xl:text-[80px] font-semibold  ">$9.99</span><span className="text-indigo-500 text-2xl font-semibold  ">/month</span></div>
    <div className=" text-center text-zinc-300 px-4 text-[15px]  lg:text-xl font-medium  ">Receives a minimum of 70% of the monthly membership in crypto monthly</div>
    <div className="border-wrap rounded-full">
    <button className="gradient-btn px-8 py-4 rounded-[32px] module justify-center items-center gap-2.5 inline-flex">
        <div className=" text-xl font-semibold">Buy now</div>
    </button>
    </div>
</div>
</>

<>
<div  className="w-full max-w-[333px] gradient-box   h-[400px] lg:h-[430px]  2xl:h-[460px] p-6  bg-[#151313] rounded-[32px]  flex-col justify-start items-center gap-10 inline-flex">
    <div className="text-zinc-100  text-5xl   font-medium  ">Plan 3</div>
    <div><span className="gradient-text-price text-[50px] xl:text-[80px] font-semibold  ">$49.99</span><span className="text-indigo-500 text-2xl font-semibold  ">/month</span></div>
    <div className=" text-center text-zinc-300 px-4 text-[15px]  lg:text-xl font-medium  ">Receives a minimum of 90% of the monthly membership in crypto monthly</div>
    <div className="border-wrap rounded-full">
    <button className="gradient-btn px-8 py-4 rounded-[32px] module justify-center items-center gap-2.5 inline-flex">
        <div className=" text-xl font-semibold  ">Buy now</div>
    </button>
    </div>
</div>
</>
  </Slider>
  <div className='text-[#FF7D05] text-center text-[14px] py-12 px-4 md:px-0 xl:py-0 md:text-lg font-medium'>All deposits are made within 15 days of membership start date</div>
  </>
  
  :
<>

<div ref={ref} className="w-full  flex  xl:flex-row justify-center items-center my-16 pb-[30px] lg:pb-0 px-8 lg:px-0">
<animated.div style={{...leftCardStyles,zIndex:1}} className="w-full max-w-[394px] gradient-box   h-[400px] lg:h-[430px]  2xl:h-[460px] p-6  bg-[#151313] rounded-[32px]  flex-col justify-start items-center gap-10 inline-flex">
    <div className="text-zinc-100  text-5xl   font-medium  ">Plan 1</div>
    <div><span className="gradient-text-price text-[50px] xl:text-[80px] font-semibold  ">$24.99</span><span className="text-indigo-500 text-2xl font-semibold  ">/month</span></div>
    <div className=" text-center text-zinc-300 px-4 text-[15px]  lg:text-xl font-medium  ">Receives a minimum of 80% of the monthly membership in crypto monthly</div>
    <div className="border-wrap rounded-full">
    <button className="gradient-btn px-8 py-4 rounded-[32px] module justify-center items-center gap-2.5 inline-flex">
        <div className=" text-xl font-semibold  ">Buy now</div>
    </button>
    </div>
</animated.div>
<animated.div style={{middleCardStyles,zIndex:3}} className="w-full max-w-[394px] gradient-box   h-[400px] lg:h-[430px]  2xl:h-[460px] p-6  bg-[#151313] rounded-[32px]  flex-col justify-start items-center gap-10 inline-flex">
    <div className="text-zinc-100  text-5xl   font-medium  ">Plan 2</div>
    <div><span className="gradient-text-price text-[50px] xl:text-[80px] font-semibold  ">$9.99</span><span className="text-indigo-500 text-2xl font-semibold  ">/month</span></div>
    <div className=" text-center text-zinc-300 px-4 text-[15px]  lg:text-xl font-medium  ">Receives a minimum of 70% of the monthly membership in crypto monthly</div>
    <div className="border-wrap rounded-full">
    <button className="gradient-btn px-8 py-4 rounded-[32px] module justify-center items-center gap-2.5 inline-flex">
        <div className=" text-xl font-semibold  ">Buy now</div>
    </button>
    </div>
</animated.div>
<animated.div style={{...rightCardStyles,zIndex:2}} className="w-full max-w-[394px] gradient-box   h-[400px] lg:h-[430px]  2xl:h-[460px] p-6  bg-[#151313] rounded-[32px]  flex-col justify-start items-center gap-10 inline-flex">
    <div className="text-zinc-100  text-5xl   font-medium  ">Plan 3</div>
    <div><span className="gradient-text-price text-[50px] xl:text-[80px] font-semibold  ">$49.99</span><span className="text-indigo-500 text-2xl font-semibold  ">/month</span></div>
    <div className=" text-center text-zinc-300 px-4 text-[15px]  lg:text-xl font-medium  ">Receives a minimum of 90% of the monthly membership in crypto monthly</div>
    <div className="border-wrap rounded-full">
    <button className="gradient-btn px-8 py-4 rounded-[32px] module justify-center items-center gap-2.5 inline-flex">
        <div className=" text-xl font-semibold  ">Buy now</div>
    </button>
    </div>
</animated.div>
</div>
<div className='text-[#FF7D05] text-center text-lg font-medium underline'>All deposits are made within 15 days of membership start date</div>
</>
}
      </div>
      <div className={`w-full py-16   relative bg-neutral-950`}>
      <main className="flex items-center justify-center w-full overflow-y-auto px-5" id="about">
          <div className="md:w-[1029px]  ">
            <div className="text-center text-zinc-100 text-[40px] md:text-[60px] uppercase bebas-neue-regular font-normal leading-[60px]  md:leading-[90px]">
              We Are The Leading International <br />
              Cryptocurrency Agency
            </div>
            <div className="text-center text-zinc-100 pt-4 text-[15px] md:text-[22px] font-medium font-Urbanist leading-[46px]">
              Somos Blockchain was designed and developed to effortlessly offer you a way
              into the crypto world. Choose your monthly
              plan and a deposit will be made into your crypto wallet monthly.
              We do all the work and research to find new and proven
              cryptocurrencies to add to your portfolio.
            </div>
          </div>
      </main>
      </div>
    </>
  )
}

export default Section4