import React from 'react'
import CreateWallet2 from '../../../assets/mob1.png'
import Subscription2 from '../../../assets/mob2.png'
import Bitcoin2 from '../../../assets/mob3.png'
import Slider from 'react-slick'
const Section2 = ({innerWidth,settings}) => {
  return (
    <>
    <div className=" w-full">
        <div
          className="w-full max-w-[220px] h-[220px] left-[50%]  lg:left-[35%] lg:mt-[180px]   absolute"
          style={{ 
            borderRadius: "220px",
            background: "rgba(254, 144, 82, 0.25)",
            filter: "blur(100px)",
            transform: "translateX(-50%)",
          }}></div>
        <div className="   items-center    justify-center flex ">
          <div className="w-[80%] pt-20 pb-[6rem]    flex flex-col justify-center ">
            <div className="text-center text-zinc-100 text-[40px] md:text-[70px] pt-[60px] font-normal bebas-neue-regular">
              Keep It Smart, Not hard
            </div>
{
  innerWidth<=1025?
  <Slider {...settings} >
          <>
          <div className=" pt-5 relative contentContainer">
            <div className="w-full max-w-[353px]  ">
              <img src={CreateWallet2} alt="" />
            </div>
          </div>
          </>
          <>
          <div className=" pt-5 relative contentContainer">
          <div className="w-full max-w-[353px]">
              <img src={Subscription2} alt="" />
            </div>
          </div>
          </>
          <>
          <div className=" pt-5 relative contentContainer">
            <p className="w-full max-w-[353px]" style={{textAlign:"center"}}>
              <img src={Bitcoin2} alt="ok" />
            </p>
          </div>
          </>
        </Slider>:
            <div className="flex  flex-col gap-8 lg:flex-row justify-center items-center pt-8">
              <div
                className=" max-w-[385px] pt-5 relative bg-contain bg-no-repeat"
                >
                <div className="w-[265px]   flex-col  gap-8 md:gap-28  ">
                <img src={CreateWallet2} alt="" />
                </div>
              </div>
              <div
                className=" max-w-[385px]  pt-5 relative bg-contain bg-no-repeat"
               >
              <div className="w-[265px]  gap-8 md:gap-28  ">
                <img src={Subscription2} alt="" />
                </div>
              </div>
              <div
                className=" max-w-[385px]  pt-5 relative bg-contain bg-no-repeat"
                >
                <div className="w-[265px]   gap-8 md:gap-28  ">
                 <img src={Bitcoin2} alt="ok" />
                </div>
              </div>
            </div>
}
          </div>
        </div>
      </div>
    </>
  )
}

export default Section2